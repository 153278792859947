import React, { useEffect, useState } from "react";
import Img1 from "../../assets/images/logo.png";
// import Img1 from "../../assets/images/logo_title.png";
// import Ribbon from "../../assets/images/ribbon.png";
// import veg from "../../assets/images/veg.png";
// import non_veg from "../../assets/images/nonveg.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSearch,
//   // faArrowRight,
//   // faCross,
// } from "@fortawesome/free-solid-svg-icons";
// import cross from "../../assets/images/cross.png";
import _ from "lodash";
// import menu_item_img from "../../assets/images/menu_images/soup.png";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import sushi_video from "../../assets/images/sushi-Menu.mp4";
import drink_video from "../../assets/images/Drink.mp4";
import bbq_video from "../../assets/images/BBQ.mp4";
import food_video from "../../assets/images/Food.mp4";
// import cafe_video from "../../assets/images/cafe_1.mp4";
import { Helmet } from "react-helmet";
// import Loader from "../loader";

const Ametrine24Menu = ({ location }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const [searchedMenuData, setSearchedMenuData] = useState([]);
  // const [isOpen, setIsOpen] = useState();
  const [searchedMenu, setSearchedMenu] = useState();
  const [menuList, setMenuList] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      imageData: allFile(filter: { relativeDirectory: { eq: "menu_images" } }) {
        nodes {
          id
          relativePath
          childImageSharp {
            id
            gatsbyImageData
          }
        }
      }
    }
  `);
  // console.log(data, "data");

  // const MenuCategory = [
  //   {
  //     category: "Salad",
  //     Details: [],
  //   },
  //   {
  //     category: "Soups",
  //     Details: [],
  //   },
  //   {
  //     category: "Bar Bites",
  //     Details: [],
  //   },
  //   {
  //     category: "Rissoto",
  //     Details: [],
  //   },
  //   {
  //     category: "Appetizers",
  //     Details: [],
  //   },
  //   {
  //     category: "Deserts",
  //     Details: [],
  //   },
  //   {
  //     category: "Sides",
  //     Details: [],
  //   },
  // ];
  const MenuData = location?.state?.detailData;
  const MenuGroupCode = location?.state?.group;
  // console.log(MenuGroupCode, "menuData");

  useEffect(() => {
    if (MenuData && MenuData.length > 0) {
      // let tempListData = [];
      let tempData = [];
      MenuData.forEach((item) => {
        let idx = tempData.findIndex(
          (oo) => oo.category_name === item.category
        );
        if (idx === -1) {
          tempData.push({ category_name: item.category, itemDeatil: [item] });
        } else {
          tempData[idx].itemDeatil.push(item);
        }
        // if (groupedData[item.group]) {
        //   groupedData[item.group].push(item);
        // } else {
        //   groupedData[item.group] = [item];
        // }
      });
      setMenuList(tempData);
      // setIsLoading(false);
    }
    return () => {
      setMenuList([]);
      // setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    if (searchedMenu) {
      // console.log(searchedMenu);
      setSearchedMenuData(
        MenuData.filter((element) =>
          element.menu_name.toLowerCase().includes(searchedMenu.toLowerCase())
        )
      );
      // setIsLoading(false);
    } else {
      setSearchedMenuData(menuList);
      // setIsLoading(false);
    }
    return () => {
      setSearchedMenuData([]);
    };
  }, [searchedMenu]);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap"
          rel="stylesheet"
        ></link>
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
      </Helmet>
      <div className=" font-Nunito" id="player">
        <div className="h-auto">
          <div className="max-w-md mx-auto bg-secondary-lightblue-300 px-4 min-h-screen">
            <div className="pb-4 fixed z-50 top-0 inset-x-0 bg-secondary-lightblue-300 h-auto">
              <div className="flex justify-center items-center">
                <img src={Img1} className="w-64 h-24" alt="icon" />
              </div>
              <div className="flex pb-4">
                {/* <div
                  className={`bg-white rounded-full h-8 w-8 items-center flex justify-center ${
                    isOpen ? `hidden` : `block`
                  }`}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div> */}
                <div
                  className={`transition-width transition-height duration-700 ease-in-out ${
                    // isOpen
                    `w-full opacity-100 block nav-open`
                    // : `w-0 h-0 opacity-0 hidden`
                  } lg:w-auto lg:h-auto opacity-100 px-4`}
                >
                  <form className="flex items-center">
                    <input
                      name="search"
                      placeholder="Search..."
                      required={true}
                      type="text"
                      value={searchedMenu}
                      className="w-full border border-secondary-darkblue-500 rounded p-1 px-2 focus:ring-1"
                      onChange={(e) => {
                        // e.preventDefault();
                        setSearchedMenu(e.target.value);
                      }}
                    />
                    {/* <i
                      className="-ml-6"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setSearchedMenu("");
                      }}
                    >
                      <img src={cross} className="h-4 w-4" alt="close_icon" />
                    </i> */}
                  </form>
                </div>
              </div>
              <div className="border border-secondary-darkblue-500 w-full"></div>
            </div>
            {/* {(MenuGroupCode !== "LONGEST HAPPY HOURS 1 + 1 OFFER" ||
              MenuGroupCode !== "CAFE") && ( */}
            <video
              width="390px"
              height="220px"
              className={`${
                MenuGroupCode === "BAR" ||
                MenuGroupCode === "BBQ" ||
                MenuGroupCode === "FOOD"
                  ? "mt-40 z-0 relative py-4 block w-full"
                  : "hidden"
              }`}
              autoPlay
              loop
              muted
            >
              <source
                src={
                  MenuGroupCode === "BAR"
                    ? drink_video
                    : MenuGroupCode === "FOOD"
                    ? food_video
                    : MenuGroupCode === "BBQ"
                    ? bbq_video
                    : null
                }
                type="video/mp4"
              />
            </video>
            {/* )} */}

            <div
              className={`${
                MenuGroupCode === "LONGEST HAPPY HOURS 1 + 1 OFFER" ||
                MenuGroupCode === "CAFE"
                  ? "mt-40 z-0 relative py-6"
                  : ""
              }`}
            >
              {!searchedMenu &&
                menuList &&
                menuList.length > 0 &&
                menuList.map((aa) => {
                  return (
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <div className="text-2xl text-secondary-darkblue-500 uppercase font-extrabold text-left pb-4">
                          {aa.category_name}
                        </div>
                        {MenuGroupCode !== "LONGEST HAPPY HOURS 1 + 1 OFFER" &&
                          (aa.category_name === "VODKA" ||
                            aa.category_name === "GIN" ||
                            aa.category_name === "RUM" ||
                            aa.category_name === "TEQUILA" ||
                            aa.category_name === "APERITIFS & LIQUEURS" ||
                            aa.category_name === "BRANDY" ||
                            aa.category_name === "WHISKEY" ||
                            aa.category_name === "WHISKY'S OF THE WORLD" ||
                            aa.category_name === "SINGLE MALT SCOTCH WHISKY" ||
                            aa.category_name === "WHITE WINE" ||
                            aa.category_name === "RED WINE" ||
                            aa.category_name === "ROSE WINE" ||
                            aa.category_name === "SPARKLING WINE") && (
                            <div className="flex text-sm font-bold text-secondary-darkblue-500 mr-2 pb-4">
                              <div className="mr-2">30 ml</div>
                              <div>BTL</div>
                            </div>
                          )}
                      </div>
                      <video
                        width="390px"
                        height="220px"
                        className={`${
                          aa.category_name === "SUSHI"
                            ? "pb-4 block w-full"
                            : "hidden"
                        }`}
                        autoPlay
                        loop
                        muted
                      >
                        <source src={sushi_video} type="video/mp4" />
                      </video>

                      {aa.itemDeatil.map((element) => {
                        return element.image_id === "" ? (
                          <>
                            <div className="">
                              <div className="pb-6">
                                <div className="flex justify-between pb-1.5">
                                  {/* <img
                                  src={
                                    element.menutype === "veg" ? veg : non_veg
                                  }
                                  className="h-4 w-4 mr-2 mt-2"
                                  alt="veg_icon"
                                /> */}
                                  <div className="flex-1">
                                    <div className="text-sm pb-1 text-secondary-darkblue-500">
                                      {element.menu_name}
                                    </div>
                                    <div className="text-xs text-secondary-lightblue-200">
                                      {element.menu_desc}
                                    </div>
                                  </div>
                                  <div className={`flex justify-between ml-4`}>
                                    <div className="text-xs text-secondary-darkblue-500 mr-2">
                                      {element.price_1 === "" ? "-" : "₹"}{" "}
                                      {element.price_1}{" "}
                                    </div>
                                    {element?.price_2 && (
                                      <div className="text-xs text-secondary-darkblue-500">
                                        {element.price_1 === "" ? "-" : "₹"}{" "}
                                        {element.price_2}{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="border-b border-secondary-lightblue-200"></div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="p-4 bg-secondary-gray-300 rounded-md shadow-xl mb-4">
                            <div className="flex justify-between">
                              <div className="mr-4">
                                {data.imageData.nodes
                                  .filter((xx) => xx.id === element.image_id)
                                  .map((image) => (
                                    <GatsbyImage
                                      image={
                                        image.childImageSharp.gatsbyImageData
                                      }
                                      key={image.id}
                                      className="w-24 h-24 rounded-full"
                                    />
                                  ))}
                              </div>
                              <div className="flex-1 flex flex-col justify-between">
                                <div>
                                  <div className="text-sm pb-1 text-secondary-darkblue-500">
                                    {element.menu_name}
                                  </div>
                                  <div className="text-xs text-secondary-lightblue-200">
                                    {element.menu_desc}
                                  </div>
                                </div>
                                <div
                                  className={`flex ${
                                    element.price_2 !== ""
                                      ? "justify-end"
                                      : "justify-between"
                                  }`}
                                >
                                  <div className="text-sm text-secondary-darkblue-500 mr-2">
                                    {element.price_1 === "" ? "-" : "₹"}{" "}
                                    {element.price_1}{" "}
                                  </div>
                                  {element?.price_2 && (
                                    <div className="text-sm text-secondary-darkblue-500">
                                      {element.price_1 === "" ? "-" : "₹"}{" "}
                                      {element.price_2}{" "}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              {searchedMenu &&
                searchedMenuData &&
                searchedMenuData.length > 0 &&
                Object.keys(_.groupBy(searchedMenuData, "category")).map(
                  (aa) => {
                    return (
                      <div className="w-full">
                        <div className="flex items-center justify-between">
                          <div className="text-xl text-secondary-darkblue-500 font-extrabold uppercase text-left pb-4">
                            {aa}
                          </div>
                          {(aa === "VODKA" ||
                            aa === "GIN" ||
                            aa === "RUM" ||
                            aa === "TEQUILA" ||
                            aa === "APERITIFS & LIQUEURS" ||
                            aa === "BRANDY" ||
                            aa === "WHISKEY" ||
                            aa === "WHISKY'S OF THE WORLD" ||
                            aa === "SINGLE MALT SCOTCH WHISKY" ||
                            aa === "WHITE WINE" ||
                            aa === "RED WINE" ||
                            aa === "ROSE WINE" ||
                            aa === "SPARKLING WINE") && (
                            <div className="flex text-sm text-secondary-darkblue-500 mr-2 pb-4">
                              <div className="mr-2">30 ml</div>
                              <div>BTL</div>
                            </div>
                          )}
                        </div>
                        <video
                          width="390px"
                          height="220px"
                          className={`${
                            aa === "SUSHI" ? "pb-4 block w-full" : "hidden"
                          }`}
                          autoPlay
                          loop
                          muted
                        >
                          <source src={sushi_video} type="video/mp4" />
                        </video>
                        <div className="">
                          {searchedMenuData &&
                            searchedMenuData
                              .filter((xx) => xx.category === aa)
                              .map((element) => {
                                return element.image_id === "" ? (
                                  <div className="px-2">
                                    <div className="pb-6">
                                      <div className="flex justify-between pb-1.5">
                                        {/* <img
                                        src={
                                          element.menutype === "veg" ? veg : non_veg
                                        }
                                        className="h-4 w-4 mr-2 mt-2"
                                        alt="veg_icon"
                                      /> */}
                                        <div className="flex-1">
                                          <div className="text-sm pb-1 text-secondary-darkblue-500">
                                            {element.menu_name}
                                          </div>
                                          <div className="text-xs text-secondary-lightblue-200">
                                            {element.menu_desc}
                                          </div>
                                        </div>
                                        <div className="flex justify-between ml-4">
                                          <div className="text-xs text-secondary-darkblue-500 mr-2">
                                            {element.price_1 === "" ? "-" : "₹"}{" "}
                                            {element.price_1}{" "}
                                          </div>
                                          {element?.price_2 && (
                                            <div className="text-xs text-secondary-darkblue-500">
                                              {element.price_1 === ""
                                                ? "-"
                                                : "₹"}
                                              {element.price_2}{" "}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="border-b border-secondary-lightblue-200"></div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="p-4 bg-secondary-gray-300 rounded-md shadow-xl mb-4">
                                    <div className="flex justify-between">
                                      <div className="mr-4">
                                        {data.imageData.nodes
                                          .filter(
                                            (xx) => xx.id === element.image_id
                                          )
                                          .map((image) => (
                                            <GatsbyImage
                                              image={
                                                image.childImageSharp
                                                  .gatsbyImageData
                                              }
                                              key={image.id}
                                              className="w-24 h-24 rounded-full"
                                            />
                                          ))}
                                      </div>
                                      <div className="flex-1 flex flex-col justify-between">
                                        <div>
                                          <div className="text-sm pb-1 text-secondary-darkblue-500">
                                            {element.menu_name}
                                          </div>
                                          <div className="text-xs text-secondary-lightblue-200">
                                            {element.menu_desc}
                                          </div>
                                        </div>
                                        <div
                                          className={`flex ${
                                            element.price_2 !== ""
                                              ? "justify-end"
                                              : "justify-between"
                                          }`}
                                        >
                                          <div className="text-sm text-secondary-darkblue-500 mr-2">
                                            {element.price_1 === "" ? "-" : "₹"}{" "}
                                            {element.price_1}{" "}
                                          </div>
                                          {element?.price_2 && (
                                            <div className="text-xs text-secondary-darkblue-500">
                                              {element.price_1 === ""
                                                ? "-"
                                                : "₹"}
                                              {element.price_2}{" "}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ametrine24Menu;
